















































import { Component, Mixins, Prop } from 'vue-property-decorator'

import { BaseCartMixin, IBaseCart } from '../../../shared/mixins/base-cart.mixin'
import { CartAnalyticsMixin } from '../../../../shared/mixins/cart-analytics.mixin'
import { CartViewMixin } from '../../../../shared/mixins/cartView.mixin'
import { FullscreenLoader } from '../../../../shared/molecules/Loader'
import { IToastMixin, ToastMixin } from '../../../../shared'
import { SidesLayout } from '../../../../shared/molecules/SidesLayout'

import { CartSummary } from '../../../organisms/CartSummary'
import { PredictedDelivery } from '../partials/PredictedDelivery.vue'
import { SingleCartItem } from '../../../organisms/SingleCartItem'
import { translateToCartItem } from '../../../molecules/CartItem/CartItem.helpers'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<Basic>({
  name: 'Basic',
  components: { CartSummary, SidesLayout, SingleCartItem, FullscreenLoader, PredictedDelivery },
  mounted (): void {
    if (this.cart) {
      this.eventBus.emit('app:cart.view', this.getCartViewPayload(this.cart))
    }
  }
})
export class Basic extends Mixins<IToastMixin, IBaseCart, CartViewMixin, CartAnalyticsMixin>(
  ToastMixin,
  BaseCartMixin,
  CartViewMixin,
  CartAnalyticsMixin
) {
  @Prop({ type: Boolean, required: false, default: false })
  public readonly isDayHoliday!: boolean

  public translateToCartItem = translateToCartItem
}

export default Basic
